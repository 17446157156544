import { Box, Button, TextField, Typography } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import { basicProps } from '../../utils/interfaces'
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';

export default function Test(props: basicProps) {


  const onReady = () => {
    
    const data = {
      loaderText: "Traitement..."
    }
    
    axiosApi.post("tests/test.php", QueryString.stringify(data))
  };
    

    return (
        <div>

            <br /><br /><br />


            <Box    
                sx={{
                    boxShadow: 2,
                    p: 3
                }}
            >


                <Button 
                    type='submit'
                    variant='contained' 
                    fullWidth
                    onClick={onReady}
                >
                    Send
                </Button>

            </Box>


        </div>
    )
}
