import { useEffect, useState } from 'react';
import { Avatar, Box, Button, LinearProgress, Stack, Typography } from '@mui/material'
import { basicProps, prizePoolCampaignType } from '../../../../../utils/interfaces'
import TaskStatusBadge from './TaskStatusBadge';
import axiosApi from '../../../../../functions/axiosApi';
import QueryString from 'qs';
import TelegramIcon from '@mui/icons-material/Telegram';
import { toast } from 'react-toastify';
import { isPC } from '../../../../../functions/basicFunctions';


interface myProps extends basicProps {
    campaignData: prizePoolCampaignType
}

export default function TelegramSubscriptionTask(props: myProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    const campaignData = props.campaignData;

    const [telegramSubscriptionStatus, setTelegramSubscriptionStatus] = useState('not_completed');
    const [statusLoaded, setStatusLoaded] = useState(false);
    let taskIsCompleted = telegramSubscriptionStatus === 'completed';

    useEffect(() => { 
        checkTelegramSubscription()
    }, [])


    const checkTelegramSubscription = () => {

        setStatusLoaded(false);

        axiosApi.post("airdrops/prize-pools/check-telegram-channel-subscription-status.php", QueryString.stringify({campaignID: campaignData.id}))
        .then(response => {

            setStatusLoaded(true);

            if(response.data.status === 'success') {
                setTelegramSubscriptionStatus(response.data.telegramSubscriptionStatus);
            }
        })

    }

    const telegramLogin = () => {

        const _window: any = window;

        if(_window.Telegram) {

            _window.Telegram.Login.auth(
                { bot_id: 8159483367, request_access: true, lang: 'fr'},
                (data) => {
                    
                    if (!data) {
                        toast.warn("Connexion avec Telegram refusée.")
                    }

                    else {

                        const _data = {
                            auth_data: data,
                            loaderText: 'Connexion en cours...'
                        }


                        axiosApi.post("settings/telegram/add-telegram-account.php", QueryString.stringify(_data))
                        .then(response => {

                            if(response.data.status === 'success') {
                                basicDatas.setAuthUser(response.data.auth_user);
                            }
                        })
                    }
            
                }
            );
        }
    }
    

    return (
        <Box
            sx={{
                p: 2,
                mb: 5,
                bgcolor: '#F7F7F7',
                borderLeft: taskIsCompleted ? '5px solid green' : 'none',
                borderRadius: 3
            }}
        >

            <Stack
                direction='row'
                spacing={isPC ? 3 : 1.5}
            >

                <TelegramIcon
                    color='primary'
                    sx={{
                        width: isPC ? 50 : 40,
                        height: isPC ? 50 : 40
                    }}
                />
                <Typography>

                    {
                        !statusLoaded &&
                        <Typography sx={{mb: 1.5}}>
                            <LinearProgress 
                                color='info' 
                                sx={{  
                                    margin: 'auto',
                                    display: 'block'
                                }} 
                            />
                        </Typography>
                    }

                    <TaskStatusBadge 
                        status={taskIsCompleted ? 'completed' : 'not_completed'} 
                        sx={{mb: 1}}
                    />

                    <Typography variant='h6' fontWeight='bold' className='Montserrat'>
                        S'abonner au canal Telegram
                    </Typography>

                    <Typography sx={{opacity: 0.8}}>
                        Abonnez-vous au canal Telegram de { basicDatas.appName }.

                        <Typography sx={{mb: 1}} />

                        {
                            !taskIsCompleted &&
                            <Typography variant='caption'>
                                <b>Pour que la tâche soit complétée, vous devez connecter votre compte Telegram et vous abonner au canal de CRYPTO-FIAT. Partagez également le canal à vos amis pour les inviter à rejoindre.</b>
                            </Typography>
                        }
                    </Typography>

                    <Typography>

                        <Button 
                            variant='outlined'
                            className='Montserrat'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2
                            }}
                            endIcon={
                                authUser.telegramInfo 
                                ?   <Avatar 
                                        src={authUser.telegramInfo.telegramAvatarUrl} 
                                        sx={{
                                            width: 20, 
                                            height: 20
                                        }} 
                                    />
                                :   <TelegramIcon/>
                            }
                            onClick={telegramLogin}
                            disabled={authUser.telegramInfo ? true : false}
                        >
                            {authUser.telegramInfo ? 'Associé à ' + authUser.telegramInfo.telegramFirstName : 'Associer avec Telegram'}
                        </Button>
                    </Typography>

                    {
                        !taskIsCompleted &&

                        <a 
                            href='https://t.me/cryptofiatex' 
                            target='_blank'
                            onClick={(e) => { if(!authUser.telegramInfo) e.preventDefault()}}
                        >
                            <Button
                                variant='outlined'
                                className='Montserrat'
                                sx={{
                                    fontWeight: 'bold',
                                    mt: 2
                                }}
                                endIcon={<TelegramIcon/>}
                                disabled={authUser.telegramInfo ? false : true}
                                onClick={() => {
                                    setTimeout(() => {
                                        checkTelegramSubscription()
                                    }, 30000);
                                }}
                            >
                                S'abonner au canal
                            </Button>
                        </a>
                    }

                    
                </Typography>
            </Stack>
        </Box>
    )
}


