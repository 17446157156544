import { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material'
import { basicProps, prizePoolCampaignType } from '../../../../../utils/interfaces'
import { Link } from 'react-router-dom';
import TaskStatusBadge from './TaskStatusBadge';
import axiosApi from '../../../../../functions/axiosApi';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import QueryString from 'qs';
import { isPC } from '../../../../../functions/basicFunctions';

interface myProps extends basicProps {
    campaignData: prizePoolCampaignType
}

export default function MakeTransactionTask(props: myProps) {

    const basicDatas = props.basicDatas;
    const campaignData = props.campaignData;

    const [makeTransactionStatus, setMakeTransactionStatus] = useState('not_completed');
    const [statusLoaded, setStatusLoaded] = useState(false);
    let taskIsCompleted = makeTransactionStatus === 'completed';

    useEffect(() => {
        
        axiosApi.post("airdrops/prize-pools/check-make-transaction-tasks-status.php", QueryString.stringify({campaignID: campaignData.id}))
        .then(response => {

            setStatusLoaded(true);

            if(response.data.status === 'success') {
                setMakeTransactionStatus(response.data.makeTransactionStatus)
            }
        })
    }, [])
    

    return (
        <Box
            sx={{
                p: 2,
                mb: 5,
                bgcolor: '#F7F7F7',
                borderLeft: taskIsCompleted ? '5px solid green' : 'none',
                borderRadius: 3
            }}
        >

            <Stack
                direction='row'
                spacing={isPC ? 3 : 1.5}
            >

                <CurrencyExchangeIcon
                    color='primary'
                    sx={{
                        width: isPC ? 50 : 40,
                        height: isPC ? 50 : 40
                    }}
                />
                <Typography>

                    {
                        !statusLoaded &&
                        <Typography sx={{mb: 1.5}}>
                            <LinearProgress 
                                color='info' 
                                sx={{  
                                    margin: 'auto',
                                    display: 'block'
                                }} 
                            />
                        </Typography>
                    }

                    <TaskStatusBadge 
                        status={taskIsCompleted ? 'completed' : 'not_completed'} 
                        sx={{mb: 1}}
                    />

                    <Typography variant='h6' fontWeight='bold' className='Montserrat'>
                        Effectuer une transaction
                    </Typography>

                    <Typography sx={{opacity: 0.8}}>
                        EEffectues une transaction d'ACHAT ou de VENTE Crypto sur { basicDatas.appName }.

                        <Typography sx={{mb: 1}} />

                        <Typography variant='caption'>
                            {!taskIsCompleted && <b>Pour que la tâche soit complétée, vous devez effectuer au moins une transaction de plus de 50'000 FCFA pendant la période en cours de cette cagnotte. </b>} <b>Plus vous effectuez des transactions, plus vos chances augmentent.</b>
                        </Typography>
                    </Typography>


                    <Link to={'/sponsorships/'}>
                        <Button
                            variant='outlined'
                            className='Montserrat'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2
                            }}
                        >
                            {taskIsCompleted ? "Effectuer plus de transactions" : 'Effectuer une transaction'}
                        </Button>
                    </Link>

                    
                </Typography>
            </Stack>
        </Box>
    )
}

