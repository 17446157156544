import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { kycDemandType } from './WaitingDemands';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';

interface myProps {
    demand: kycDemandType
}

export default function VerifyDocumentID(props: myProps) {

    const demand = props.demand;
    const user = demand.userInfos;

    const [open, setOpen] = useState(false);
    const [demands, setDemands] = useState<kycDemandType[]>([])
    const [loading, setLoading] = useState(true);

    const documentID = demand.documentID;

    useEffect(() => {

        if(open) {
        
            axiosApi.post("ctr/kyc/verify-document-id.php", QueryString.stringify({documentID, user_id: demand.userInfos.user_id}))
            .then(response => {

                setLoading(false)

                if(response.data.status === 'success') {
                    setDemands(response.data.demands)
                }
            }).catch(() => {
                setLoading(false)
            })
        }

    }, [open])
    

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>

            <Typography 
                color='primary'
                sx={{mb: 2, cursor: 'pointer'}}
                onClick={() => setOpen(true)}
            >
                Vérifier le numéro du document
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{maxWidth: '550px', margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Vérifier le numéro document</DialogTitle>
                
                <DialogContent dividers={true}>

                    {
                        loading &&
                        <Typography sx={{textAlign: 'center'}}>
                            Chargement...
                        </Typography>
                    }


                    <Typography 
                        variant='h5'
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            mb: 2
                        }}
                    >
                        {documentID}
                    </Typography>

                    {
                        demands.length === 0 &&
                        <Typography variant='caption'>
                            Aucun autre document ne correspond
                        </Typography>
                    }

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin:'auto' }}>
                            
                        {
                            demands.map((itemDemand: kycDemandType) => {

                                const userInfos = itemDemand.userInfos;

                                if(demand.id === itemDemand.id) return <></>

                                return (

                                    <div key={itemDemand.id}>

                                        <ListItem 
                                            alignItems="flex-start" 
                                            onClick={() => window.open('/ctr/kyc/' + userInfos.user_id + '/')}
                                            sx={{cursor: 'pointer'}}
                                        >

                                            <ListItemAvatar sx={{cursor:'pointer'}}>
                                                <Link to={"/ctr/users/" + userInfos.user_id + "/"} target='_blank' className='link'>
                                                    <Avatar alt={userInfos.lastName + " " + userInfos.firstName} src={userInfos.avatarUrl} />
                                                </Link>
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={ userInfos.firstName + " " + userInfos.lastName }
                                                secondary={
                                                    <>

                                                        <Typography
                                                            sx={{ display: 'block' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            Document : <b>{itemDemand.documentID}</b>
                                                        </Typography>

                                                    </>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />

                                    </div>
                                )
                            })
                        }  

                    </List>


                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
                
            </Dialog>

        </Box>
    )
}
